import {
    FETCH_USER,
    UPDATE_USER,
    SET_CURRENT_USER,
    FETCH_USER_PREFERENCE,
    UPDATE_USER_PREFERENCE,
    UNFETCH_USER,
} from '../types/types';


export const userReducer = (state = {}, action) => {
    switch(action.type){
        case FETCH_USER:
            return {...state, ...action.payload};
        case UNFETCH_USER:
            return state
        case SET_CURRENT_USER:
            return {...state, ...action.payload}
        case UPDATE_USER:
            return {...state, ...action.payload};
        default:
            return state;
    }
}

export const userPreferenceReducer = (state = {}, action) => {
    switch(action.type){
        case FETCH_USER_PREFERENCE:
            return {...state, [action.payload.idUserPreference]: action.payload}
        case UPDATE_USER_PREFERENCE:
            return {...state, [action.payload.idUserPreference]: action.payload}
        default:
            return state;
    }
}