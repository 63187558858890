import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';

import translationHR from './locales/hr/translation.json';

// const resources = {
//   en: {
//     translation: {
//       "msgWelcome": "Operation succeeded",
//       "btn": {
//         "edit": "Edit",
//         "delete": "Delete"
//       },
//       "confirm": {
//         "title": "Are you sure?",
//         "text": "You won't be able to revert this!",
//         "btn": {
//           "cancel": "Cancel",
//           "confirm": "Yes, delete!",
//           "ok": "Ok"
//         },
//         "error": {
//           "title": "Oops...",
//           "connectedItems": "Selected item has connected items and can't be deleted!"
//         },
//         "success": {
//           "title": "Success!",
//           "text": "Selected items was deleted!"
//         }
//       },
//       "idCountry": "ID",
//       "countryName": "Country name",
//       "codeISO2": "Country ISO2",
//       "codeISO3": "Country ISO3"
//     }
//   }
// };


const resources = {
  en: {
    translation: translationEN
  },
  hr: {
    translation:translationHR
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'hr',
    fallbackLng: 'hr'
  });

export default i18n;