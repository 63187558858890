import {
  OLX_ADD_SPARE_PART_TO_PUBLISH,
  OLX_REMOVE_SPARE_PART_FROM_PUBLISH
} from '../types/types'

const INITIAL_STATE = {
  sparePartToPublishID: null  
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
      case OLX_ADD_SPARE_PART_TO_PUBLISH:
          return {...state, sparePartToPublishID: action.payload};
      case OLX_REMOVE_SPARE_PART_FROM_PUBLISH:
          return {...state, sparePartToPublishID: null }
      default:
          return state;
  }
  
}