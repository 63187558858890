import {
    LOG_IN, LOG_OUT
} from '../types/types';

const INITIAL_STATE = {
    isLoggedIn: null,
    criteria: {}
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case LOG_IN:
            return {...state, isLoggedIn: true, criteria: {...action.payload}};
        case LOG_OUT:
            return {...state, isLoggedIn: false, criteria: {}}
        default:
            return state;
    }
    
}
// export default (state = INITIAL_STATE, action) => {
//     switch(action.type){
//         case SIGN_IN:
//             return {...state, isSignedIn: true, userToken: action.payload };
//         case SIGN_OUT:
//             return {...state, isSignedIn: false, userToken: null}
//         default:
//             return state;
//     }
    
// }


