// import external modules
import { combineReducers } from "redux";
// import internal(own) modules

// import { countryReducer, deleteCountryReducer } from './countryReducer';

import authReducer from './authReducer';
import { userReducer, userPreferenceReducer } from './userReducer';
import olxReducer from './olxReducer';

const rootReducer = combineReducers({
   auth: authReducer,
   currentUser: userReducer,
   userPreference: userPreferenceReducer,
   olx: olxReducer
});

export default rootReducer;
