// // Event Action Types
// export const ADD_EVENT = "ADD_EVENT";

// // Chat Action Types
// export const HANDLE_FILTER_USER = "HANDLE_FILTER_USER";
// export const HANDLE_SELECT_USER = "HANDLE_SELECT_USER";
// export const HANDLE_SEND_MESSAGE = "HANDLE_SEND_MESSAGE";


// User Action types

export const FETCH_USER = 'FETCH_USER';
export const UNFETCH_USER = 'UNFETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_CURRENT_USER = 'DECODE_USER';
export const UPDATE_USER_PREFERENCE = 'UPDATE_USER_PREFERENCE';
export const FETCH_USER_PREFERENCE = 'FETCH_USER_PREFERENCE';

export const LOG_IN = 'LOG_IN';
export const LOG_OUT = 'LOG_OUT';

export const OLX_ADD_SPARE_PART_TO_PUBLISH = 'OLX_ADD_SPARE_PART_TO_PUBLISH';
export const OLX_REMOVE_SPARE_PART_FROM_PUBLISH = 'OLX_REMOVE_SPARE_PART_FROM_PUBLISH';