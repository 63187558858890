import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose } from 'redux';
import { store } from "./redux/storeConfig/store";
import { CookiesProvider } from 'react-cookie';

import "./index.scss";
import Spinner from './components/Spinner/Spinner';

import "font-awesome/css/font-awesome.min.css";

const App = lazy(() => import('./components/App/App'));

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <Suspense fallback={<Spinner />}>
                <App />
            </Suspense>
        </Provider>
    </CookiesProvider>,
    document.querySelector("#root")
)